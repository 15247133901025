import React from 'react';
import './style.scss'
import logo from "../../imagens/logo-digiplas.png"
function Header() {
  return (
    <div className='container-fluid header'>
      <img src={logo} alt="" />
      {/* <a href="https://catalogo.digiplas.com.br" target='_blank' rel="noreferrer">Catálogo</a> */}
    </div>
  );
}

export default Header;