import React from 'react';
import './style.scss'
import imgLogo from '../../imagens/logo-digiplas.png';
import { AiOutlineInstagram,AiFillFacebook,AiFillLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"></link>

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row pt-2 pb-2">
          <div className="col-12 col-md-4 centro">
            <p>©2025 Digiplas. Todos os direitos reservados.</p>
          </div>
          <div className="col-12 col-md-4 text-center yan centro">
            {/* <p>Desenvolvido por <a href="https://www.linkedin.com/in/yan-ueno/" target="_blank">Yan Ueno</a></p> */}
          </div>
          <div className="col-12 col-md-4 text-center icons">
            <a href="https://www.instagram.com/digiplas_comercial/" target="_blank"><AiOutlineInstagram/></a>
            <a href="https://www.facebook.com/digiplas.digiplas.1" target="_blank"><AiFillFacebook/></a>
            <a href="https://www.linkedin.com" target="_blank"><AiFillLinkedin/></a>
          </div>
          <div className="cnpj col-12 text-center pt-2 pb-2">
            DIGIPLAS DISTRIBUIDORA (Digiplas Comercial Ltda. – CNPJ 67.806.638/0003-57)
          </div>
        </div>
      </div>



      {/* <div className="col-md-4 col-sm-6">
        <img className="imagem-logo" alt="imagem-logo"src={imgLogo} />
        <br/>
        <p className="name"> Digiplas &copy; 2016</p>
      </div> */}

    </footer>
  );
}

export default Footer;